// 新增客户信息弹窗
<template>
    <el-dialog :close-on-click-modal="false"
               :title="title"
               :visible="true"
               width="800px"
               :before-close="close">
        <el-form :model="form"
                 ref="form"
                 :rules="rules"
                 label-width="150px"
                 :inline="false"
                 size="normal">
            <el-form-item label="页面名称">
                <el-input v-model="form.title"
                          placeholder="路由title"
                          @keyup.enter.native="onSubmit"
                          clearable />
            </el-form-item>

            <el-form-item label="组件路径">
                <el-input v-model="form.path"
                          placeholder="路由path"
                          clearable />
            </el-form-item>
            <el-form-item label="描述信息">
                <el-input v-model="form.remark"
                          placeholder="描述信息"
                          clearable />
            </el-form-item>
            <el-form-item label="页面icon">
                <el-input v-model="form.icon"
                          placeholder="路由图标"
                          clearable />
            </el-form-item>
            <el-form-item label="类型">
                <el-select v-model="form.type"
                           filterable
                           placeholder="当前保存为页面或者权限">
                    <el-option v-for="item in options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="排序">
                <el-input v-model="form.ord"
                          placeholder="排序"
                          clearable />
            </el-form-item>

            <el-form-item label="组件NAME">
                <el-input v-model="form.name"
                          placeholder="路由的name"
                          clearable />
            </el-form-item>

            <el-form-item label="数据权限管理">
                <el-select v-model="form.isAuthor"
                           placeholder="">
                    <el-option v-for="item in permissions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="无权限方案">
                <el-select v-model="form.noAuthorMethod"
                           placeholder="">
                    <el-option key="HIDDEN"
                               label="隐藏"
                               value="HIDDEN">
                    </el-option>
                    <el-option key="NOT_EDITABLE"
                               label="不可编辑"
                               value="NOT_EDITABLE">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="权限类型">
                <el-select v-model="form.authorType"
                           placeholder="">
                    <el-option key="PROJECT_AUTHOR"
                               label="项目模块"
                               value="PROJECT_AUTHOR">
                    </el-option>
                    <el-option key="OTHER_AUTHOR"
                               label="其他模块"
                               value="OTHER_AUTHOR">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label=""
                          v-show="form.isAuthor === true">
                <el-checkbox-group v-model="form.sectionAuthorList">
                    <el-checkbox v-for="(item,index) in permissionList"
                                 :key="index"
                                 :label="item.code">{{item.label}}</el-checkbox>
                </el-checkbox-group>
            </el-form-item>

            <el-form-item label="页面请求地址">
                <el-input v-model="urlItem"
                          placeholder="输入后回车会自动拼接到下方文本域"
                          @keyup.enter.native="addUrl"
                          clearable></el-input>
                <el-input v-model="form.url"
                          type="textarea"
                          rows="5"
                          placeholder="请输入本页面的请求地址，使用英文逗号分隔"
                          size="normal"></el-input>
            </el-form-item>

            <el-form-item label="tab对应状态配置">
                <el-input v-model="statusItem"
                          placeholder="输入后回车会自动拼接到下方文本域"
                          @keyup.enter.native="addStatus"
                          clearable></el-input>
                <el-input v-model="form.status"
                          type="textarea"
                          rows="5"
                          placeholder="请输入本页面的请求地址，使用英文逗号分隔"
                          size="normal"></el-input>
            </el-form-item>

            <el-form-item class="footer">
                <el-button type="danger"
                           @click="close">取消</el-button>
                <el-button type="primary"
                           @click="onSubmit">确认</el-button>
            </el-form-item>

            <!-- TWO_LEVEL -->
        </el-form>

    </el-dialog>
</template>
<script>
import systemManageApi from "@/api/systemManageApi.js";
export default {
    name: "popAddPage",
    components: {},
    props: {
        title: {
            type: String,
            default: "新增",
        },
        formData: {
            type: Object,
            default: null,
        },
    },
    watch: {
        formData: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                newVal &&
                    (this.form = this.commonJs.deepCopy(
                        Object.assign({}, this.form, newVal, {
                            sectionAuthorList: newVal.sectionAuthors
                                ? newVal.sectionAuthors.split(",")
                                : [],
                        })
                    ));
                if (this.form.noAuthorMethod && this.form.noAuthorMethod.name) {
                    this.form.noAuthorMethod = this.form.noAuthorMethod.name;
                }
            },
        },
        "form.title": {
            immediate: true,
            handler: function () {
                this.form.remark = this.form.title;
            },
        },
    },
    data() {
        return {
            urlItem: "", // 请求地址地址
            statusItem: "", // 保存数据库内的status
            form: {
                name: "",
                title: "",
                path: "", //地址
                icon: "",
                isAuthor: false, //数据权限
                sectionAuthorList: [], //数据权限 选中数据
                url: "", // 请求地址集合
                status: "", // tab对应状态集合
            },
            options: [
                {
                    value: "MODULE",
                    label: "菜单类型",
                },
                {
                    value: "PAGE",
                    label: "页面",
                },
                {
                    value: "DETAIL",
                    label: "详情",
                },
                {
                    value: "BUTTON_AUTHOR",
                    label: "功能权限",
                },
            ],
            permissions: [
                {
                    value: true,
                    label: "是",
                },
                {
                    value: false,
                    label: "否",
                },
            ],
            permissionList: [], //数据权限枚举项  菜单
            rules: {},
        };
    },
    mounted() {
        this.getAuthorSectionType();
    },
    methods: {
        // 提交表单
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.createDeviceType();
                } else {
                    this.$message({
                        message: "请检查必填内容是否填写！",
                        type: "error",
                    });
                    return;
                }
            });
        },
        //新增用户信息
        createDeviceType() {
            this.isloading = true;
            systemManageApi
                .saveMenuInfo([this.form])
                .then(() => {
                    this.$message({ message: "操作成功", type: "success" });
                    this.isloading = false;
                    this.$emit("close");
                    this.$emit("refresh");
                })
                .catch(() => {
                    this.isloading = false;
                });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },

        //获取菜单权限枚举项
        getAuthorSectionType() {
            this.$store.dispatch("getAuthorSectionType").then((v) => {
                this.permissionList = v;
                console.log(v);
            });
        },

        // 向文本域增加url
        addUrl() {
            if (this.form.url) {
                this.form.url = `${this.form.url},\n${this.urlItem}`;
            } else {
                this.form.url = this.urlItem;
            }
            this.urlItem = "";
        },
        // 向文本域增加tab对应状态
        addStatus() {
            if (this.form.status) {
                this.form.status = `${this.form.status},\n${this.statusItem}`;
            } else {
                this.form.status = this.statusItem;
            }
            this.statusItem = "";
        },
    },
    computed: {},
};
</script>
<style scoped>
</style>