// 菜单功能管理
<template>
    <div class="softwareInfoManage baseBg">
        <div class="searchBar ofh">
            <div class="flr">
                <el-button type="success"
                           @click="addNewChildren(null)">新增目录</el-button>
            </div>
        </div>

        <div class="listBox">
            <div class="tableBox">
                <el-table :data="tableData"
                          border
                          :height="tableHeight"
                          v-loading="isloading"
                          row-key="id"
                          stripe
                          :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                    <el-table-column prop="title"
                                     label="权限名称/页面名称">
                    </el-table-column>
                    <el-table-column prop="pathName"
                                     width="140"
                                     label="组件NAME">
                    </el-table-column>
                    <el-table-column prop="remark"
                                     label="描述">
                    </el-table-column>
                    <el-table-column prop="level.text"
                                     width="55"
                                     label="层级">
                    </el-table-column>
                    <el-table-column prop="id"
                                     width="200"
                                     label="id">
                    </el-table-column>
                    <el-table-column prop="path"
                                     width="200"
                                     label="组件路径">
                    </el-table-column>
                    <el-table-column label="操作"
                                     width="300">
                        <template slot-scope="scope">
                            <el-link type="primary"
                                     v-if="scope.row.type.name !== 'BUTTON_AUTHOR' && scope.row.type.name !== 'DETAIL'"
                                     @click="addNewChildren(scope.row,'add')">新增子页面</el-link>
                            <el-link type="success"
                                     v-if="scope.row.type.name !== 'BUTTON_AUTHOR'"
                                     @click="editTable(scope.row,'edit')">编辑页面</el-link>
                            <el-link type="primary"
                                     v-if="scope.row.type.name === 'DETAIL'"
                                     @click="addNew(scope.row)">新增权限</el-link>
                            <el-link type="success"
                                     v-if="scope.row.type.name === 'BUTTON_AUTHOR'"
                                     @click="editTable(scope.row,'edit')">编辑权限</el-link>

                            <el-popconfirm confirmButtonText='确认'
                                           cancelButtonText='取消'
                                           icon="el-icon-info"
                                           iconColor="red"
                                           @confirm="deleteTable(scope.row)"
                                           title="确定删除吗？">
                                <el-link slot="reference"
                                         type='danger'>删除</el-link>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <!-- <Pagination :page="searchForm.page"
                            :limit="searchForm.size"
                            :total="pageTotal"
                            @pagination="paginationChange" /> -->
            </div>
        </div>
        <popAddPage v-if="systemDialogVisible"
                    :formData="rowData"
                    title="页面"
                    @close="closeSystemDialog"
                    @refresh="search" />

        <popAddRole v-if="popAddRoleVisible"
                    :formData="rowData"
                    title="权限"
                    @close="closeSystemDialog"
                    @refresh="search" />
    </div>
</template>

<script>
import systemManageApi from "@/api/systemManageApi.js";
// import Pagination from "@/components/Pagination"; // 分页
import popAddPage from "./components/menuManagement/popAddPage";
import popAddRole from "./components/menuManagement/popAddRole";
export default {
    name: "menuManagement",
    props: [],

    components: {
        // Pagination,
        popAddPage,
        popAddRole,
    },

    data() {
        return {
            systemDialogVisible: false,
            popAddRoleVisible: false,
            searchForm: {},
            // pageTotal: 0,
            isloading: true,
            rowData: null,
            tableData: [],
        };
    },

    created() {},

    mounted() {
        this.search();
    },

    methods: {
        /* 获取列表 */
        getList(searchForm = this.searchForm) {
            this.isloading = true;
            systemManageApi
                .getMenuTable(searchForm)
                .then((res) => {
                    this.isloading = false;
                    this.tableData = res.content;
                })
                .catch(() => {
                    this.isloading = false;
                });
        },
        details(e) {
            this.$router.push({
                path: "/baseInfoManage/systemDetail",
                query: { systemInfo: JSON.stringify(e) },
            });
        },
        // 搜索
        search() {
            this.getList();
        },
        // 新增权限
        addNew(data) {
            this.rowData = Object.assign({}, { pid: data.id });
            this.popAddRoleVisible = true;
        },
        // 新增子节点
        addNewChildren(data, edit) {
            this.rowData = edit
                ? Object.assign({}, data, {
                      isAdd: data ? true : false,
                      id: null,
                      pid: data.id,
                      type: data.type.name,
                      authorType: data.authorType.name,
                  })
                : null;
            this.systemDialogVisible = true;
        },

        // 新增或編輯系統彈框 关闭
        closeSystemDialog() {
            this.systemDialogVisible = false;
            this.popAddRoleVisible = false;
        },
        // 表格的删除操作
        deleteTable(row) {
            this.isloading = true;
            systemManageApi
                .deleteMenuInfo(row.id)
                .then(() => {
                    this.$message({
                        message: "删除成功",
                        type: "success",
                    });
                    this.getList();
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        // 编辑页面
        editTable(row) {
            this.rowData = Object.assign({}, row, {
                type: row.type.name,
                level: row.level.name,
                name: row.pathName,
                pid: row.parentId,
                authorType: row.authorType.name,
            });
            this.systemDialogVisible = true;
        },

        // 编辑页面权限
        editRole(row) {
            this.rowData = {
                roleList: [
                    Object.assign(
                        {},
                        {
                            pid: row.pid,
                            id: row.id,
                            title: row.title,
                            type: row.type.name,
                            level: row.level.name,
                            noAuthorMethod: row.noAuthorMethod.name,
                            remark: row.remark,
                            authorType: row.authorType.name,
                        }
                    ),
                ],
                edit: true,
            };
            this.popAddRoleVisible = true;
        },

        //修改状态
        changeStatus(row) {
            console.log(row);
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 165 });
        },
    },

    watch: {},
};
</script>

<style lang='scss'>
.roleTable .el-table__body tr.current-row > td {
    background-color: #b9c5a9 !important;
}
</style>
