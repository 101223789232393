import { render, staticRenderFns } from "./popAddPage.vue?vue&type=template&id=bcc44562&scoped=true&"
import script from "./popAddPage.vue?vue&type=script&lang=js&"
export * from "./popAddPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcc44562",
  null
  
)

export default component.exports