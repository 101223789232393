// 新增目录
<template>
    <el-dialog :close-on-click-modal="false"  :title="title"
               :visible="true"
               width="800px"
               :before-close="close">
        <el-form ref="form"
                 :model="form"
                 :rules="rules"
                 label-width="150px"
                 :inline="false"
                 size="normal">

            <el-row class="roleBox"
                    v-for="(item,index) in form.roleList"
                    :key="index">
                <el-form-item label="权限名称">
                    <el-input v-model="item.title"
                              placeholder=""
                              clearable />
                </el-form-item>
                <el-form-item label="权限描述">
                    <el-input v-model="item.remark"
                              placeholder=""
                              clearable />
                </el-form-item>
                <el-form-item label="无权限方案">
                    <el-select v-model="item.noAuthorMethod"
                               placeholder="">
                        <el-option key="HIDDEN"
                                   label="隐藏"
                                   value="HIDDEN">
                        </el-option>
                        <el-option key="NOT_EDITABLE"
                                   label="不可编辑"
                                   value="NOT_EDITABLE">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="权限类型">
                    <el-select v-model="item.authorType"
                               placeholder="">
                        <el-option key="PROJECT_AUTHOR"
                                   label="项目模块"
                                   value="PROJECT_AUTHOR">
                        </el-option>
                        <el-option key="OTHER_AUTHOR"
                                   label="其他模块"
                                   value="OTHER_AUTHOR">
                        </el-option>
                    </el-select>
                </el-form-item>
                <i @click.prevent="removeDomain(index)"
                   style="position: absolute;left:15px;top:48%;font-size:22px;cursor: pointer;"
                   class="el-icon-error"></i>
            </el-row>

            <el-form-item v-if="!this.form.edit">
                <el-button @click.prevent="addDomain">添加字段</el-button>
            </el-form-item>

            <el-form-item class="footer">
                <el-button type="danger"
                           @click="close">取消</el-button>
                <el-button type="primary"
                           @click="onSubmit">确认</el-button>
            </el-form-item>
        </el-form>

    </el-dialog>
</template>
<script>
import systemManageApi from "@/api/systemManageApi.js";
export default {
    name: "popAddRole",
    components: {},
    props: {
        title: {
            type: String,
            default: "新增",
        },
        formData: {
            type: Object,
            default: null,
        },
    },
    watch: {
        formData: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                newVal &&
                    (this.form = this.commonJs.deepCopy(
                        Object.assign({}, this.form, newVal)
                    ));
            },
        },
    },
    data() {
        return {
            form: {
                roleList: [],
            },
            rules: {},
        };
    },
    mounted() {},
    methods: {
        removeDomain(index) {
            this.form.roleList.splice(index, 1);
        },
        addDomain() {
            this.form.roleList.push({
                title: "", //权限名称  根据这个来处理页面元素
                remark: "", //权限描述
                noAuthorMethod: "HIDDEN",
                pid: this.form.pid,
                authorType: "OTHER_AUTHOR",
            });
        },
        // 提交表单
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                console.log(valid);
                if (valid) {
                    this.createDeviceType();
                } else {
                    this.$message({
                        message: "请检查必填内容是否填写！",
                        type: "error",
                    });
                    return;
                }
            });
        },
        //新增用户信息
        createDeviceType() {
            this.isloading = true;
            systemManageApi
                .saveMenuInfo(this.form.roleList)
                .then(() => {
                    this.$message({ message: "操作成功", type: "success" });
                    this.isloading = false;
                    this.$emit("close");
                    this.$emit("refresh");
                })
                .catch(() => {
                    this.isloading = false;
                });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },
    computed: {},
};
</script>
<style scoped>
.roleBox {
    border-bottom: 1px dashed #ccc;
    margin-bottom: 15px;
}
</style>